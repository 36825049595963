import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../utils/ApiService";

const Loading = () => {

    const location = useLocation();
    const navigation = useNavigate();

    useEffect(() => {
        let code = location.state?.code
        ApiService.getPdfToPrint(code)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {
                let iframe;

                if (iframe) {
                    iframe.parentNode.removeChild(iframe);
                }

                iframe = document.createElement("iframe");
                let pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
                // eslint-disable-next-line
                let iframeUrl = URL.createObjectURL(pdfBlob);
                // eslint-disable-next-line
                iframe.src = iframeUrl;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                iframe.addEventListener("load", function() {
                    function printIframe() {
                        try {
                            iframe.focus()
                            try {
                                iframe.contentWindow.document.execCommand("print", false, null);
                            } catch (e) {
                                handlePrintEvent("/error")
                            }
                        } catch (error) {
                            handlePrintEvent("/error")
                        } finally {
                            iframe.style.visibility = "hidden";
                            iframe.style.left = "-1px";
                            URL.revokeObjectURL(iframeUrl);
                            handlePrintEvent("/confirm", code)
                        }
                    }


                    window.setTimeout(printIframe, 3000);

                })

                document.body.appendChild(iframe);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrintEvent = (destination, code) => {
        const timer = setTimeout(() => {
            clearTimeout(timer)
            navigation(destination, {state: {code: code}})
        }, 10000);
    }

        return (
            <div className={'content-loader position-relative'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 702 342" className={'border-loader'}>
                    <rect x="36" width="18" height="18" className={"cube-black"} id={'cube-0'}/>
                    <rect x="72" width="18" height="18" className={"cube-black"} id={'cube-1'}/>
                    <rect x="108" width="18" height="18" className={"cube-black"} id={'cube-2'}/>
                    <rect x="144" width="18" height="18" className={"cube-black"} id={'cube-3'}/>
                    <rect x="180" width="18" height="18" className={"cube-black"} id={'cube-4'}/>
                    <rect x="216" width="18" height="18" className={"cube-black"} id={'cube-5'}/>
                    <rect x="252" width="18" height="18" className={"cube-black"} id={'cube-6'}/>
                    <rect x="288" width="18" height="18" className={"cube-black"} id={'cube-7'}/>
                    <rect x="324" width="18" height="18" className={"cube-black"} id={'cube-8'}/>
                    <rect x="360" width="18" height="18" className={"cube-black"} id={'cube-9'}/>
                    <rect x="396" width="18" height="18" className={"cube-black"} id={'cube-10'}/>
                    <rect x="432" width="18" height="18" className={"cube-black"} id={'cube-11'}/>
                    <rect x="468" width="18" height="18" className={"cube-black"} id={'cube-12'}/>
                    <rect x="504" width="18" height="18" className={"cube-black"} id={'cube-13'}/>
                    <rect x="540" width="18" height="18" className={"cube-black"} id={'cube-14'}/>
                    <rect x="576" width="18" height="18" className={"cube-black"} id={'cube-15'}/>
                    <rect x="612" width="18" height="18" className={"cube-black"} id={'cube-16'}/>
                    <rect x="648" width="18" height="18" className={"cube-black"} id={'cube-17'}/>
                    <rect x="684" width="18" height="18" className={"cube-black"} id={'cube-18'}/>

                    <rect x="684" y="36" width="18" height="18" className={"cube-black"} id={'cube-19'}/>
                    <rect x="684" y="72" width="18" height="18" className={"cube-black"} id={'cube-20'}/>
                    <rect x="684" y="108" width="18" height="18" className={"cube-black"} id={'cube-21'}/>
                    <rect x="684" y="144" width="18" height="18" className={"cube-black"} id={'cube-22'}/>
                    <rect x="684" y="180" width="18" height="18" className={"cube-black"} id={'cube-23'}/>
                    <rect x="684" y="216" width="18" height="18" className={"cube-black"} id={'cube-24'}/>
                    <rect x="684" y="252" width="18" height="18" className={"cube-black"} id={'cube-25'}/>
                    <rect x="684" y="288" width="18" height="18" className={"cube-black"} id={'cube-26'}/>
                    <rect x="684" y="324" width="18" height="18" className={"cube-black"} id={'cube-27'}/>

                    <rect x="648" y="324" width="18" height="18" className={"cube-black"} id={'cube-28'}/>
                    <rect x="612" y="324" width="18" height="18" className={"cube-black"} id={'cube-29'}/>
                    <rect x="576" y="324" width="18" height="18" className={"cube-black"} id={'cube-30'}/>
                    <rect x="540" y="324" width="18" height="18" className={"cube-black"} id={'cube-31'}/>
                    <rect x="504" y="324" width="18" height="18" className={"cube-black"} id={'cube-32'}/>
                    <rect x="468" y="324" width="18" height="18" className={"cube-black"} id={'cube-33'}/>
                    <rect x="432" y="324" width="18" height="18" className={"cube-black"} id={'cube-34'}/>
                    <rect x="396" y="324" width="18" height="18" className={"cube-black"} id={'cube-35'}/>
                    <rect x="360" y="324" width="18" height="18" className={"cube-black"} id={'cube-36'}/>
                    <rect x="324" y="324" width="18" height="18" className={"cube-black"} id={'cube-37'}/>
                    <rect x="288" y="324" width="18" height="18" className={"cube-black"} id={'cube-38'}/>
                    <rect x="252" y="324" width="18" height="18" className={"cube-black"} id={'cube-39'}/>
                    <rect x="216" y="324" width="18" height="18" className={"cube-black"} id={'cube-40'}/>
                    <rect x="180" y="324" width="18" height="18" className={"cube-black"} id={'cube-41'}/>
                    <rect x="144" y="324" width="18" height="18" className={"cube-black"} id={'cube-42'}/>
                    <rect x="108" y="324" width="18" height="18" className={"cube-black"} id={'cube-43'}/>
                    <rect x="72" y="324" width="18" height="18" className={"cube-black"} id={'cube-44'}/>
                    <rect x="36" y="324" width="18" height="18" className={"cube-black"} id={'cube-45'}/>

                    <rect y="324" width="18" height="18" className={"cube-black"} id={'cube-46'}/>
                    <rect y="288" width="18" height="18" className={"cube-black"} id={'cube-47'}/>
                    <rect y="252" width="18" height="18" className={"cube-black"} id={'cube-48'}/>
                    <rect y="216" width="18" height="18" className={"cube-black"} id={'cube-49'}/>
                    <rect y="180" width="18" height="18" className={"cube-black"} id={'cube-50'}/>
                    <rect y="144" width="18" height="18" className={"cube-black"} id={'cube-51'}/>
                    <rect y="108" width="18" height="18" className={"cube-black"} id={'cube-52'}/>
                    <rect y="72" width="18" height="18" className={"cube-black"} id={'cube-53'}/>
                    <rect y="36" width="18" height="18" className={"cube-black"} id={'cube-54'}/>
                    <rect width="18" height="18" className={"cube-black"} id={'cube-55'}/>



                </svg>
                <div className={'position-absolute content-text'}>
                    <p className={'text-primary'}>Please wait …</p>
                    <p>Data are being processed</p>
                </div>
            </div>

        );
}

export default Loading;