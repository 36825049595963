import React from "react";
import {Button} from "react-bootstrap";
import ApiService from "../utils/ApiService";
import {useLocation, useNavigate} from "react-router-dom";

const Confirm = () =>{

    const location = useLocation();
    const navigation = useNavigate();

    const handleBadgePrintingState = (event, status) => {
        event.preventDefault();

        if(status === 1){
            let code = location.state?.code
            ApiService.endProcess(code).then(response => {
                navigation("/printed")
            })
        } else {
            navigation("/error")
        }
    }

    return(
        <div className={'content-all'}>
            <div className={'content-split-two'}>
                <div className={'two-row'}>
                    <div className={'item-box-border-red w-100 h-100 d-flex justify-content-center align-items-center'}>
                        <h2 className={'text-primary main-title-end text-center'}>Is your badge printed correctly?</h2>
                    </div>
                </div>
                <div className={'two-row'}>
                    <div className={'d-flex justify-content-center align-items-center h-100 gap-5'}>
                            <Button className={'btn-large'} variant={'primary'} size={"lg"} onClick={event => handleBadgePrintingState(event, 1)}>Yes</Button>
                            <Button className={'btn-large'} variant={'outline-primary'} size={"lg"} onClick={event => handleBadgePrintingState(event, 0)}>No</Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Confirm;