import React, {useEffect, useRef} from "react";
import scanImage from '../img/ICML_Illustration_Scan.svg';
import ApiService from '../utils/ApiService'
import {useNavigate} from "react-router-dom";

const Home = () =>{

    const qrInputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            qrInputRef.current.focus();
    }, 1000);

        return () => {
            clearInterval(interval)
        }
    },[]);


    const handleScannedCode = (e) => {
        e.preventDefault();
        let inputVal = e.target.elements.scannerview.value;
        inputVal = inputVal.replace(/'/g, "-");

        console.log("ScannedCode: " + inputVal)
        ApiService.checkIsCodeValid(inputVal)
            .then(userData => {
                console.log("UserData: "+userData);
                navigate("/validate", {state: {data: userData, code: inputVal}})
            })
            .catch(error => navigate("/error"))
    }

    return(
        <div className={'content-all'}>
            <div className={'content-split-two'}>
                <div className={'two-row'}>
                    <div className={'item-box-border-red w-100 h-100 d-flex justify-content-center align-items-center'}>
                        <h1 className={'text-primary main-title text-center'}>Self check-in</h1>
                    </div>
                </div>
                <div className={'two-row'}>
                    <div className={'d-flex justify-content-center align-items-center h-100'}>
                        <div className={'pe-3 w-100 d-flex justify-content-end'}>
                            <img src={scanImage} alt={'figure how to scan'} className={'img-fluid image-scan'}/>
                        </div>
                        <div className={'ps-3 w-100'}>
                            <p>Please scan the QR code<br/>
                                displayed on your<br/>
                                registration confirmation<br/>
                            </p>
                            <form onSubmit={(e) => handleScannedCode(e)} id="scanForm">
                                <input ref={qrInputRef} type="text" id="scanner" name="scannerview" className={'custom-input'}/>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Home;