import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Base from "./template/base";
import Home from "./pages/home";
import Printed from "./pages/printed";
import Load from "./pages/load";
import Error from "./pages/error";
import Validate from "./pages/validate";
import Confirm from "./pages/confirm";


function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Base/>}>
            <Route index={true} element={<Home/>}/>
            <Route path={'/printed'} element={<Printed/>}/>
            <Route path={'/loading'} element={<Load/>}/>
            <Route path={'/error'} element={<Error/>}/>
            <Route path={'/validate'} element={<Validate/>}/>
            <Route path={'/confirm'} element={<Confirm/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
