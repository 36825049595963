import React, {useEffect} from "react";
import alert from '../img/ICML_Illustration_Error.svg';
import {Row, Col}from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

const Error = () =>{

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/")
        }, 5000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Row className={'content-alert p-5 bg-primary'}>
            <Col xs={12}>
                <p className={'text-white mb-5'}>A problem has occurred</p>
            </Col>
            <Col xs={12}>
                <Row>
                    <Col>
                        <p className={'text-white mb-0'}>Please go to the<br/>
                            registration desk</p>
                    </Col>
                    <Col xs={4}>
                        <img src={alert} alt={'alert icon'} className={'img-fluid'} />
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default Error;