import React, {useEffect} from "react";
import printImage from '../img/ICML_Illustration_Print.svg';
import {useNavigate} from "react-router-dom";

const Printed = () =>{

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/")
        }, 5000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <div className={'content-all'}>
            <div className={'content-split-two'}>
                <div className={'two-row'}>
                    <div className={'item-box-border-red w-100 h-100 d-flex justify-content-center align-items-center'}>
                        <h1 className={'text-primary main-title-end text-center'}>Please collect your badge</h1>
                    </div>
                </div>
                <div className={'two-row'}>
                    <div className={'d-flex justify-content-center align-items-center h-100'}>
                        <div>
                            <img src={printImage} alt={'figure of printing'} className={'img-fluid image-scan'}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Printed;