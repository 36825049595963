class APIService {
    baseUrl = process.env.REACT_APP_API_BASE_URL

    constructor() {
        console.log("BaseUrl: "+this.baseUrl)
    }
    async checkIsCodeValid(scannedCode) {
        return fetch(`${this.baseUrl}/badge/validate?content=${scannedCode}`, {
            headers: getHeader(),
            method: "GET",
        }).then(res => this.handleFetchResponse(res))
    }

    async getPdfToPrint(code){
       return fetch(`${this.baseUrl}/badge/print?content=${code}&selfcheckin=selfcheckin`, {
            headers: getHeaderPdf(),
            method: "GET",
        })
    }

    async endProcess(code){
        fetch(`${this.baseUrl}/badge/printconfirm?content=${code}`, {
            headers: getHeader(),
            method: "GET"
        })
    }

    handleFetchResponse(res) {
        return res.json()
            .catch(() => {
                return Promise.reject({
                    status: res.status,
                    ok: res.ok,
                    message: "",
                    body: ""
                });
            })
    }
}

const api = new APIService();
export default api;

export function getHeader(){
    return  {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
}

export function getHeaderPdf(){
    return  {
        "Accept": "application/pdf;charset=UTF-8",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
}