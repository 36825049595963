import React from "react";
import { Outlet } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";

const Base = () =>{

    return(
        <main>
            <Container fluid={'xxl'}>
                <Row className={'justify-content-center vh-100'}>
                    <Col xs={12} className={'d-flex h-100 justify-content-center align-items-center overflow-hidden'}>

                            <Outlet />

                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default Base;